import React from 'react';
import PropTypes from 'prop-types';
import AddRanch from './AddRanch';
import RanchesList from './RanchesList';
import RanchesPerformanceBlock from './RanchesPerformanceBlock';
import RanchesFiltering from './RanchesFiltering';
import SearchRanches from './SearchRanches';
import './RanchesBlock.scss';

const RanchesBlock = ({ ranches, handleRanchClick, listRef }) => (
    <div className="ranches-container">
        <RanchesFiltering ranches={ranches} />
        <RanchesPerformanceBlock ranches={ranches} />
        <AddRanch ranches={ranches} />
        <SearchRanches ranches={ranches} handleRanchClick={handleRanchClick} />
        <RanchesList ranches={ranches} ref={listRef} />
    </div>
);

export default RanchesBlock;

RanchesBlock.propTypes = {
    ranches: PropTypes.arrayOf(PropTypes.shape()),
    handleRanchClick: PropTypes.func,
    listRef: PropTypes.shape(),
};
