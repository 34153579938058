import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { arrayOfObjectsShallowEqual } from '@beewise/react-utils';
import { getTotalPerformances } from 'components/views/BrokerView/utils';
import { getBeekeepers, getSelectedBrokerFilter } from 'components/views/BrokerView/selectors';
import PerformanceItem from './PerformanceItem';
import './RanchesPerformanceBlock.scss';

const RanchesPerformanceBlock = ({ ranches }) => {
    const selectedBrokerFilter = useSelector(getSelectedBrokerFilter);
    const allBeekeepers = useSelector(state => getBeekeepers(state, selectedBrokerFilter), arrayOfObjectsShallowEqual);
    const { available, contracted, assigned, planned, placed } = getTotalPerformances({
        ranches,
        allBeekeepers,
    });

    return (
        <div className="ranches-performance-container">
            <PerformanceItem label="Available" value={available} />
            <PerformanceItem label="Contracted" value={contracted} />
            <PerformanceItem label="Assigned / Planned" value={`${assigned} / ${planned}`} />
            <PerformanceItem label="Placed" value={placed} />
        </div>
    );
};

export default RanchesPerformanceBlock;

RanchesPerformanceBlock.propTypes = {
    ranches: PropTypes.arrayOf(PropTypes.shape()),
};
