import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import MapInfo from 'components/reusables/Map/components/MapInfo';
import MapV2 from 'components/reusables/Map/components/MapV2';
import LocationMarker from 'components/reusables/Map/components/LocationMarkerv2';
import useFormMethods from './hooks/useFormMethods';
import PerformanceWidget from './PerformanceWidget';
import Toolbar from './Toolbar';
import useMap from './hooks/useMap';
import { TOOLBAR_MODES } from './utils';
import './Map.scss';

const MapView = ({ isToolbarEnabled }) => {
    const {
        addPolygon,
        addAccessRoad,
        createLocation,
        removePolygon,
        removeLocation,
        updateLocation,
        updatePolygon,
        form,
        locations,
    } = useFormMethods();

    const { mode, onModeChange, map, isDraggingRef, handleEditLocationMarker, onGoogleApiLoaded } = useMap({
        addPolygon,
        addAccessRoad,
        removePolygon,
        updatePolygon,
        isToolbarEnabled,
        createLocation,
        removeLocation,
        updateLocation,
        form,
    });

    return (
        <section className={cn('map-view', mode)}>
            <MapV2
                extraOptions={
                    isToolbarEnabled && (
                        <>
                            <Toolbar mode={mode} setMode={onModeChange} map={map} />
                            <MapInfo />
                            <PerformanceWidget control={form.control} />
                        </>
                    )
                }
                onGoogleApiLoaded={onGoogleApiLoaded}
            >
                {locations.map((location, index) => (
                    <LocationMarker
                        lat={location.lat}
                        lng={location.lng}
                        // resets indexes after removal, if wierd behaviour would exists - wrap LocationMarker in FormField and pass control inside
                        key={`${location._id}-${index}`}
                        markerId={location.id}
                        marker={location}
                        draggable={mode === TOOLBAR_MODES.CREATE_LOCATION.title}
                        onDragEnd={(_, { latLng: { lat, lng } }) => {
                            isDraggingRef.current = false;
                            updateLocation(index, { ...location, lat, lng });
                        }}
                        onDrag={() => {
                            isDraggingRef.current = true;
                        }}
                        onClick={handleEditLocationMarker({ location, index, mode, isDraggingRef })}
                    />
                ))}
            </MapV2>
        </section>
    );
};

MapView.propTypes = {
    isToolbarEnabled: PropTypes.bool,
};

export default memo(MapView);
