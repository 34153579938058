import { useForm } from 'react-hook-form';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getDirtyFormFields } from '@beewise/react-utils';
import { useNavigate, useParams } from 'react-router-dom';
import { ROOT_ERRORS } from 'components/reusables/Form/Errors';
import { getSelectedRanch, getSelectedBrokerFilter } from '../../../selectors';
import { fetchCreateRanchDraft, fetchUpdateRanchMain } from '../../../actions';

const getDefaultValues = ({ selectedRanch, brokerOptions, selectedBrokerFilter, isEdit }) => {
    let matchedOption;

    if (!isEdit) {
        matchedOption = brokerOptions.some(option => option.value === selectedBrokerFilter);
    }

    return {
        brokerUserId: matchedOption ? selectedBrokerFilter : (selectedRanch?.brokerInfo?.id ?? null),
        name: selectedRanch?.name ?? '',
        companyId: selectedRanch?.companyId ?? '',
        ranchManagerId: selectedRanch?.ranchManagerId ?? '',
        contactIds: selectedRanch?.contacts?.map(({ id }) => id) ?? [],
        requiredHivesAmount: selectedRanch?.requiredHivesAmount ?? 0,
        totalArea: selectedRanch?.totalArea ?? 0,
        crop: selectedRanch?.crop ?? '',
        quality: selectedRanch?.quality ?? '',
        trees: selectedRanch?.trees ?? [],
    };
};

const useInitStep1FormData = ({ setIsFirstStep, brokerOptions }) => {
    const { id } = useParams();
    const isEdit = !!id;
    const selectedRanch = useSelector(getSelectedRanch, shallowEqual);
    const selectedBrokerFilter = useSelector(getSelectedBrokerFilter);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const form = useForm({
        defaultValues: getDefaultValues({
            selectedRanch: id ? selectedRanch : null,
            brokerOptions,
            selectedBrokerFilter,
            isEdit,
        }),
    });

    const {
        handleSubmit,
        setError,
        formState: { defaultValues },
    } = form;

    const successCallback = data => {
        setIsFirstStep(false);
        if (!isEdit) {
            navigate(`/ranch/${data.id}`);
        }
    };

    const handleSetError = error => setError(ROOT_ERRORS.SERVER_ERROR, { message: error, type: 500 });

    const onCancel = () => (isEdit ? setIsFirstStep(false) : navigate(-1));

    const onSubmit = handleSubmit(data => {
        const body = getDirtyFormFields({
            initialFields: defaultValues,
            newFields: data,
            fieldsToStringify: ['trees'],
        });

        if (!isEdit && defaultValues.brokerUserId) {
            body.brokerUserId = defaultValues.brokerUserId;
        }

        const handler = isEdit ? fetchUpdateRanchMain : fetchCreateRanchDraft;
        dispatch(handler({ body, resolver: successCallback, rejector: handleSetError, id }));
    });

    return { form, onSubmit, onCancel };
};

export default useInitStep1FormData;
