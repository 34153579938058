import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-light-svg-icons';
import { SelectField } from '@beewise/select-field';
import './SearchRanches.scss';

const SearchRanches = ({ ranches, handleRanchClick }) => {
    const selectSearchRef = useRef(null);
    const [inputValue, setInputValue] = useState('');

    const ranchOptions = ranches?.map(ranch => ({
        label: ranch.name,
        value: ranch.id,
    }));

    return (
        <div className="ranches-search">
            <SelectField
                ref={selectSearchRef}
                options={ranchOptions}
                onChange={handleRanchClick}
                onInputChange={setInputValue}
                placeholder="Search"
                info={<FontAwesomeIcon icon={faSearch} />}
                isSearchable
                size="small"
                value={inputValue}
                isClearable
            />
        </div>
    );
};

export default SearchRanches;

SearchRanches.propTypes = {
    ranches: PropTypes.arrayOf(PropTypes.shape()),
    handleRanchClick: PropTypes.func.isRequired,
};
