import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { arrayOfObjectsShallowEqual } from '@beewise/react-utils';
import MapV2 from 'components/reusables/Map/components/MapV2';
import MapInfo from 'components/reusables/Map/components/MapInfo';
import { getRanches } from 'components/views/BrokerView/selectors';
import RanchMarkerV2 from 'components/reusables/Map/components/RanchMarkerV2';
import { getVisibleMarkers } from 'components/reusables/Map/components/utils';
import { setCurrentRanch } from 'components/views/BrokerView/actions';
import LocationMarkerv2 from 'components/reusables/Map/components/LocationMarkerv2';
import useMap from '../../../hooks/useMap';

const MapContainer = ({ currentRanch, handleRanchClick }) => {
    const dispatch = useDispatch();
    const ranches = useSelector(getRanches, arrayOfObjectsShallowEqual);

    const handleSetCurrentRanch = ranch => {
        handleRanchClick(ranch.id);
        dispatch(setCurrentRanch(ranch));
    };

    const mapConfig = {
        ranches,
        selectedRanch: currentRanch,
        handleSelectCurrentItem: handleSetCurrentRanch,
    };
    const { setMap, isBhomeView, bounds, handleMapChange } = useMap(mapConfig);

    const MarkerComponent = isBhomeView ? LocationMarkerv2 : RanchMarkerV2;

    const visibleMarkers = getVisibleMarkers({ isBhomeView, ranches, bounds });

    return (
        <MapV2
            onGoogleApiLoaded={({ map }) => setMap(map)}
            extraOptions={<MapInfo />}
            handleMapChange={handleMapChange}
        >
            {visibleMarkers.map(marker => (
                <MarkerComponent
                    key={marker.id}
                    marker={marker}
                    lat={marker.lat}
                    lng={marker.lng}
                    handleMarkerClick={handleSetCurrentRanch}
                />
            ))}
        </MapV2>
    );
};

export default MapContainer;

MapContainer.propTypes = {
    currentRanch: PropTypes.shape(),
    handleRanchClick: PropTypes.func,
};
