import constants from 'appConstants';

const { MARKER_HIVE_TYPES, DEFAULT_BHOME_HIVES_AMOUNT } = constants;

const getHiveTypes = ({ bhomes, standardHives, plannedBhomesAmount, plannedStandardHivesAmount }) => {
    const hasStandardHives = !!standardHives || plannedStandardHivesAmount;

    const hasBhomes = bhomes?.length || plannedBhomesAmount;

    if (hasStandardHives && hasBhomes) {
        return MARKER_HIVE_TYPES.COMBINED;
    }
    if (hasBhomes) {
        return MARKER_HIVE_TYPES.BHOME;
    }
    return MARKER_HIVE_TYPES.STANDARD_HIVES;
};

export const getMarkerType = ({ bhomes, standardHives, plannedBhomesAmount = 0, hivesAmount }) => {
    const hiveTypes = getHiveTypes({
        bhomes,
        standardHives,
        plannedBhomesAmount,
        plannedStandardHivesAmount: hivesAmount - plannedBhomesAmount * DEFAULT_BHOME_HIVES_AMOUNT,
    });
    return hiveTypes;
};
