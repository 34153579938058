import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { arrayOfObjectsShallowEqual } from '@beewise/react-utils';
import { COUNTRY_IL, COUNTRY_US } from 'utils/isIsraeliRanch';
import { fetchCompaniesWithUsers, fetchRanches, fetchUsers, setCurrentRanch } from '../../actions';
import RanchesBlock from './components/RanchesBlock';
import RanchInfo from './components/RanchInfo';
import { getRanches, getSelectedBrokerFilter } from '../../selectors';
import { smoothScroll } from './utils';

const DashboardV2 = () => {
    const dispatch = useDispatch();
    const listRef = useRef(null);
    const selectedBrokerFilter = useSelector(getSelectedBrokerFilter);
    const ranches = useSelector(getRanches, arrayOfObjectsShallowEqual);

    useEffect(() => {
        dispatch(fetchCompaniesWithUsers());
        dispatch(fetchUsers({ type: 'broker' }));
        if (selectedBrokerFilter === COUNTRY_US || selectedBrokerFilter === COUNTRY_IL) {
            dispatch(fetchRanches({ country: selectedBrokerFilter }));
        } else {
            dispatch(fetchRanches({ brokerId: selectedBrokerFilter }));
        }
    }, [dispatch, selectedBrokerFilter]);

    const scrollToRanch = useCallback(({ scrollToIndex }) => {
        if (!listRef.current) return;

        if (Number.isNaN(scrollToIndex) || scrollToIndex < 0) {
            return;
        }

        const { itemSize } = listRef.current.props;
        const itemOffset = scrollToIndex * itemSize;
        const targetOffset = itemOffset - listRef.current.props.height / 2 + itemSize / 2;
        const currentOffset = listRef.current.state.scrollOffset;

        smoothScroll(currentOffset, targetOffset, 500, listRef);
        listRef.current?.resetAfterIndex?.(0);
    }, []);

    const handleRanchClick = useCallback(
        ranchId => {
            const ranchIndex = ranches.findIndex(ranch => ranch.id === ranchId);

            if (ranchIndex < 0) {
                return;
            }

            scrollToRanch({ scrollToIndex: ranchIndex });
            dispatch(setCurrentRanch(ranches[ranchIndex]));
        },
        [dispatch, ranches, scrollToRanch]
    );

    useEffect(() => {
        if (!ranches?.length) {
            return;
        }

        listRef.current?.resetAfterIndex?.(0);
    }, [ranches?.length]);

    return (
        <>
            <RanchesBlock ranches={ranches} handleRanchClick={handleRanchClick} listRef={listRef} />
            <RanchInfo handleRanchClick={handleRanchClick} />
        </>
    );
};

export default DashboardV2;
