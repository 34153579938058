import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { shallowEqual } from '@beewise/react-utils';
import { getCurrentRanch } from 'components/views/BrokerView/selectors';
import RanchHeader from './RanchHeader';
import RanchDetails from './RanchDetails';
import MapContainer from './MapContainer';
import './RanchInfo.scss';

const RanchInfo = ({ handleRanchClick }) => {
    const currentRanch = useSelector(getCurrentRanch, shallowEqual);

    return (
        <div className="ranch-container">
            <RanchHeader currentRanch={currentRanch} />
            <MapContainer currentRanch={currentRanch} handleRanchClick={handleRanchClick} />
            <RanchDetails currentRanch={currentRanch} />
        </div>
    );
};

export default RanchInfo;

RanchInfo.propTypes = {
    handleRanchClick: PropTypes.func,
};
