import React from 'react';
import PropTypes from 'prop-types';
import { formatNumber } from 'components/views/BrokerView/utils';
import './PerformanceItem.scss';

const PerformanceItem = ({ label, value }) => (
    <div className="ranches-performance-container-item">
        <span>{formatNumber(value)}</span> {label}
    </div>
);

export default PerformanceItem;

PerformanceItem.propTypes = {
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
