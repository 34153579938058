import constants from 'appConstants';

const { MARKER_TYPES } = constants;
export const getQualityLabel = broodFramesAmount => {
    if (broodFramesAmount <= 4) {
        return 'Weak';
    }
    if (broodFramesAmount <= 8) {
        return 'Medium';
    } else return 'Strong';
};

export const calculateAverageFramesByBeehome = framesData => {
    const averageFramesByBeehome = {};

    if (!framesData) {
        return averageFramesByBeehome;
    }

    Object.entries(framesData).forEach(([beehomeId, frames]) => {
        const average = (frames.reduce((acc, val) => acc + val, 0) / frames.length).toFixed(1);
        averageFramesByBeehome[beehomeId] = { average };
    });

    return averageFramesByBeehome;
};

export const POLYGON_PARAMS = {
    capturing: true,
    strokeColor: '#feba12',
    strokeWeight: 2,
    fillColor: 'transparent',
    editable: false,
    draggable: false,
    clickable: true,
    cursor: null,
};

const POLYLINE_PARAMS = {
    strokeColor: '#4392F1',
    strokeWeight: 4,
    editable: false,
};

export const createShape = ({ map, params, ShapeType }) => {
    const shape = new ShapeType(params);
    shape.setMap(map);
    return shape;
};

export const createPolyline = ({ map, coords }) =>
    createShape({ map, params: { ...POLYLINE_PARAMS, path: coords }, ShapeType: window.google.maps.Polyline });

export const createPolygon = ({ map, polygon }) => {
    const polygonCreated = createShape({
        map,
        params: { ...POLYGON_PARAMS, paths: polygon },
        ShapeType: window.google.maps.Polygon,
    });

    polygonCreated.id = JSON.stringify(polygon);
    return polygonCreated;
};

export const getVisibleMarkers = ({ isBhomeView, ranches, bounds }) => {
    const currentMarkers = isBhomeView ? ranches.flatMap(ranch => ranch.locations) : ranches;

    if (!bounds || !currentMarkers.length) {
        return [];
    }

    return currentMarkers.filter(marker => {
        if (!marker?.lat || !marker?.lng) {
            return false;
        }
        const { lat, lng } = marker;
        return bounds?.contains({ lat, lng });
    });
};

export const getColorStyles = ({ placedHivesAmount, plannedHivesAmount }) => {
    if (!placedHivesAmount) {
        return MARKER_TYPES.EMPTY;
    }

    if (placedHivesAmount === plannedHivesAmount) {
        return MARKER_TYPES.PLACED;
    }
    return MARKER_TYPES.PARTIAL;
};
