import React, { useState, useMemo, useEffect, memo } from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { debounce } from 'lodash-es';
import SearchableGrid from '@beewise/searchable-grid';
import Button from '@beewise/button-v2';
import { SelectField } from '@beewise/select-field';
import TextField from '@beewise/text-field';
import Menu from '@beewise/menu';
import { arrayOfObjectsShallowEqual } from '@beewise/react-utils';
import { faSearch } from '@fortawesome/pro-light-svg-icons';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import constants from 'appConstants';
import PropTypes from 'prop-types';
import { getSelectedBrokerFilter, getBrokers } from 'components/views/BrokerView/selectors';
import CompanyUsersPanel from '../CompanyUsersPanel';
import { getContactsData } from '../../selectors';
import { filterContacts, getIsMatchingBroker } from './utils';

import './ContactsGrid.scss';

const ROLES = [
    { value: constants.COMPANY_TYPES.BEEKEEPER, label: 'Beekeeper' },
    { value: constants.COMPANY_TYPES.GROWER, label: 'Grower' },
    { value: constants.COMPANY_TYPES.INSTALLER, label: 'Installer' },
    { value: constants.ROLES.RANCH_MANAGER, label: 'Ranch manager' },
];

const ROLE_MAP = ROLES.reduce((acc, role) => {
    acc[role.value] = role;

    return acc;
}, {});

const RoleBadge = memo(({ role }) => {
    const roleData = ROLE_MAP[role];

    if (!roleData) {
        return <span className="role-badge unknown-role">Unknown</span>;
    }

    return <span className={`role-badge ${roleData.value}`}>{roleData.label}</span>;
});

RoleBadge.propTypes = {
    role: PropTypes.string.isRequired,
};

const MultiValueCell = memo(({ values }) => (
    <div className="cell-column">
        {values.map((item, i) => (
            <p key={`${item}${i}`}>{item?.trim()}</p>
        ))}
    </div>
));

MultiValueCell.propTypes = {
    values: PropTypes.arrayOf(PropTypes.string),
};

const getColumns = handleEdit => [
    {
        field: 'companyName',
        headerName: 'Company',
        flex: 1,
        valueGetter: ({ row }) => row.name || '-',
    },
    {
        field: 'role',
        headerName: 'Role',
        flex: 0.7,
        renderCell: ({ value }) => <RoleBadge role={value} />,
        valueGetter: ({ row }) => row.type || '-',
    },
    {
        field: 'contacts',
        headerName: 'Contacts',
        flex: 1,
        renderCell: ({ value }) => <MultiValueCell values={value} />,
        valueGetter: ({ row }) => (row.users?.length ? row.users.map(({ username }) => username) : ['-']),
    },
    {
        field: 'email',
        headerName: 'Email',
        flex: 1.2,
        renderCell: ({ value }) => <MultiValueCell values={value} />,
        valueGetter: ({ row }) => (row.users?.length ? row.users.map(({ email }) => email) : ['-']),
    },
    {
        field: 'phone',
        headerName: 'Phone number',
        flex: 0.8,
        renderCell: ({ value }) => <MultiValueCell values={value} />,
        valueGetter: ({ row }) => (row.users?.length ? row.users.map(({ phone }) => phone) : ['-']),
    },
    {
        field: 'hivesAmount',
        headerName: 'Assigned/Available Hives',
        flex: 1,
        valueGetter: ({ row }) => (row.data?.hivesAmount != null ? `${row.data.hivesAmount}` : '-'),
    },
    {
        field: 'brokers',
        headerName: 'Brokers',
        flex: 0.7,
        renderCell: ({ value }) => <MultiValueCell values={value} />,
        valueGetter: ({ row }) => (row.brokers?.length ? row.brokers.map(({ username }) => username) : ['-']),
    },
    {
        field: 'menu',
        headerName: '',
        width: '10',
        renderCell: ({ row }) => (
            <Menu
                position="bottom rigth"
                options={[
                    {
                        label: 'Edit',
                        onClick: () => handleEdit(row),
                    },
                    // {
                    //     label: 'Delete',
                    //     onClick: () => handleDelete(row),
                    // },
                ]}
            />
        ),
    },
];

export const ContactsGrid = () => {
    const contactsData = useSelector(getContactsData);
    const brokers = useSelector(getBrokers, arrayOfObjectsShallowEqual).map(({ username, id }) => ({
        value: id,
        label: username,
    }));
    const selectedBrokerFilter = useSelector(getSelectedBrokerFilter);
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedRow, setSelectedRow] = useState(null);
    const [isCompanyUsersPanelOpen, setIsCompanyUsersPanelOpen] = useState(false);

    const isMatchingBroker = getIsMatchingBroker({ selectedBrokerFilter, brokers });

    const [selectedBrokers, setSelectedBrokers] = useState(isMatchingBroker ? [selectedBrokerFilter] : []);

    const debouncedSetSearchQuery = useMemo(() => debounce(query => setSearchQuery(query), 500), []);

    const handleEdit = row => {
        setSelectedRow(row);
        setIsCompanyUsersPanelOpen(true);
    };

    const handleSearchInputChange = value => {
        setSearchInput(value);
        debouncedSetSearchQuery(value);
    };

    const handleClearSearch = () => {
        setSearchInput('');
        setSearchQuery('');
        debouncedSetSearchQuery.cancel();
    };

    const filteredContactsData = useMemo(
        () => filterContacts({ contactsData, selectedRoles, selectedBrokers, searchQuery }),
        [contactsData, selectedRoles, selectedBrokers, searchQuery]
    );

    const columns = useMemo(() => getColumns(handleEdit), []);

    const defaultValues = selectedRow || (isMatchingBroker ? { brokers: [{ id: selectedBrokerFilter }] } : null);

    useEffect(
        () => () => {
            debouncedSetSearchQuery.cancel();
        },
        [debouncedSetSearchQuery]
    );

    return (
        <>
            <div className="contacts-grid-container">
                <header className="contacts-header">
                    <h2>Contacts</h2>
                    <Button className="primary-orange" onClick={() => setIsCompanyUsersPanelOpen(true)}>
                        + Add Contact
                    </Button>
                </header>
                <div className="filters">
                    <div className="filter-selectors">
                        <SelectField
                            options={ROLES}
                            value={selectedRoles}
                            onChange={setSelectedRoles}
                            placeholder="Role"
                            isMulti
                        />
                        <SelectField
                            options={brokers}
                            value={selectedBrokers}
                            onChange={setSelectedBrokers}
                            placeholder="Broker"
                            isMulti
                        />
                    </div>
                    <div className="search-wrapper">
                        <div className="search-icon-wrapper">
                            <FontAwesomeIcon icon={faSearch} className="search-icon" />
                        </div>
                        <TextField
                            name="Search"
                            label="Search"
                            value={searchInput}
                            onChange={handleSearchInputChange}
                        />
                        {searchInput && (
                            <button className="clear-icon-wrapper" onClick={handleClearSearch}>
                                <FontAwesomeIcon icon={faTimes} className="clear-icon" />
                            </button>
                        )}
                    </div>
                </div>
                <div className="contacts-grid-table">
                    <SearchableGrid
                        columns={columns}
                        rows={filteredContactsData}
                        className="searchable-grid"
                        isSearchHidden
                        pagination
                        getRowHeight={() => 'auto'}
                        sx={{
                            height: '100%',
                            width: '100%',
                            '& .MuiDataGrid-main': { fontFamily: 'Poppins', fontSize: '13px' },
                            '& .MuiDataGrid-withBorderColor': { border: 0 },
                            '& .MuiDataGrid-columnHeaderTitleContainer': {
                                padding: '15px',
                                fontWeight: '600',
                            },
                            '& .MuiDataGrid-cellContent': { padding: '16px', fontWeight: '100' },
                        }}
                        disableColumnSelector
                        disableDensitySelector
                    />
                </div>
            </div>
            <CompanyUsersPanel
                isOpen={isCompanyUsersPanelOpen}
                onClose={() => {
                    setIsCompanyUsersPanelOpen(false);
                    setSelectedRow(null);
                }}
                roles={ROLES}
                brokers={brokers}
                defaultValues={defaultValues}
            />
        </>
    );
};
