import { useState, useCallback, useRef, useEffect } from 'react';
import { updateMapBounds } from 'components/reusables/Map/components/Map/utils';
import { createShape, POLYGON_PARAMS } from 'components/reusables/Map/components/utils';

const ZOOM_THRESHOLD = 11;

const removeMapShape = shape => {
    window.google?.maps?.event?.clearInstanceListeners(shape);
    shape.setMap(null);
};

const useMap = ({ selectedRanch, ranches, handleSelectCurrentItem }) => {
    const mapRef = useRef(null);
    const [map, setMap] = useState(null);
    const [mapOptions, setMapOptions] = useState({});
    const polyShapesRef = useRef([]);

    const isBhomeView = mapOptions?.zoom > ZOOM_THRESHOLD;

    const adjustMapZoomAndCenter = useCallback(() => {
        if (!selectedRanch || !map) {
            return;
        }

        const ranch = ranches.find(ranch => ranch.id === selectedRanch.id);

        if (!ranch) {
            return;
        }

        const points = ranch.polygons?.flatMap(polygon => polygon) || [];
        updateMapBounds(map, points);
    }, [map, ranches, selectedRanch]);

    const createAndAddShape = useCallback(
        ranch => {
            const polygon = createShape({
                ShapeType: window.google.maps.Polygon,
                map,
                params: { ...POLYGON_PARAMS, paths: ranch.polygons },
            });
            polygon.addListener('click', () => {
                handleSelectCurrentItem(ranch);
            });
            return polygon;
        },
        [handleSelectCurrentItem, map]
    );

    useEffect(() => {
        if (!map || !ranches.length) {
            return;
        }

        ranches.forEach(ranch => {
            ranch?.polygons?.length && polyShapesRef.current.push(createAndAddShape(ranch, window.google.maps.Polygon));
        });

        return () => {
            polyShapesRef.current.forEach(removeMapShape);
            polyShapesRef.current = [];
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createAndAddShape, map, ranches, removeMapShape]);

    useEffect(() => {
        adjustMapZoomAndCenter();
    }, [adjustMapZoomAndCenter, selectedRanch]);

    return {
        map,
        setMap,
        mapRef,
        isBhomeView,
        handleMapChange: setMapOptions,
        bounds: mapOptions.bounds,
    };
};

export default useMap;
