import { usePermission, AnalProvider, PermissionsProvider } from '@beewise/react-utils';
import { BrowserRouter as Router, Route, Routes as DomRoutes } from 'react-router-dom';

import { TRACKING_CODE, UI_ENV } from 'config';
import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as Sentry from '@sentry/react';
import DashboardV2 from 'components/views/BrokerView/views/DashboardV2';
import ConfProvider from '../components/reusables/ConfProvider';
import AdminAppHeader from '../components/reusables/AdminAppHeader';
import { ROUTE_PATHS } from './constants';
import PublicRoute from '../components/reusables/PublicRoute';
import SignIn from '../components/views/SignIn';
import ChangePassword from '../components/views/PasswordSetup';
import PrivateRoute from '../components/reusables/PrivateRoute';
import AdminDashboard from '../components/views/AdminView/views/Dashboard';
import AdminRanch from '../components/views/AdminView/views/BrokerRanch';
import AdminGrowers from '../components/views/AdminView/views/ManageGrowers';
import AdminInstallers from '../components/views/AdminView/views/ManageInstallers';
import AdminBeekeepers from '../components/views/AdminView/views/ManageBeekeepers';
import Dashboard from '../components/views/GrowerView';
import Conference from '../components/views/Conference';
import RanchEdit from '../components/views/BrokerView/views/RanchEdit';
import ContactsView from '../components/views/BrokerView/views/ContactsView';
import { fetchAppSettings } from '../components/reusables/AppHeader/actions';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(DomRoutes);

const BrokerRoutes = [
    <Route
        path={ROUTE_PATHS.RANCH_EDIT}
        key={ROUTE_PATHS.RANCH_EDIT}
        element={<PrivateRoute component={RanchEdit} />}
    />,
    <Route
        path={`${ROUTE_PATHS.RANCH_EDIT}/:id`}
        key={`${ROUTE_PATHS.RANCH_EDIT}/:id`}
        element={<PrivateRoute component={RanchEdit} />}
    />,
    <Route
        path={`${ROUTE_PATHS.DASHBOARD}`}
        key={`${ROUTE_PATHS.DASHBOARD}`}
        element={<PrivateRoute component={DashboardV2} />}
    />,
    <Route
        path={`${ROUTE_PATHS.CONTACTS}`}
        key={`${ROUTE_PATHS.CONTACTS}`}
        element={<PrivateRoute component={ContactsView} />}
    />,
];

const adimRoutes = [
    <Route path={ROUTE_PATHS.HOME} key={ROUTE_PATHS.HOME} element={<PrivateRoute component={AdminDashboard} />} />,
    <Route path={ROUTE_PATHS.RANCH} key={ROUTE_PATHS.RANCH} element={<PrivateRoute component={AdminRanch} />} />,
    <Route
        path={`${ROUTE_PATHS.RANCH}/:id`}
        key={`${ROUTE_PATHS.RANCH}/:id`}
        element={<PrivateRoute component={AdminRanch} />}
    />,
    <Route path={ROUTE_PATHS.GROWERS} key={ROUTE_PATHS.GROWERS} element={<PrivateRoute component={AdminGrowers} />} />,
    <Route
        path={ROUTE_PATHS.BEEKEEPERS}
        key={ROUTE_PATHS.BEEKEEPERS}
        element={<PrivateRoute component={AdminBeekeepers} />}
    />,
    <Route
        path={ROUTE_PATHS.INSTALLERS}
        key={ROUTE_PATHS.INSTALLERS}
        element={<PrivateRoute component={AdminInstallers} />}
    />,
    <Route
        path={`${ROUTE_PATHS.REPORT}/:id`}
        key={`${ROUTE_PATHS.REPORT}/:id`}
        element={<PrivateRoute component={Dashboard} />}
    />,
    ...BrokerRoutes,
];

const userRoutes = [
    <Route path={ROUTE_PATHS.HOME} key={ROUTE_PATHS.HOME} element={<PrivateRoute component={Dashboard} />} />,
    <Route
        path={ROUTE_PATHS.CONFERENCE}
        key={ROUTE_PATHS.CONFERENCE}
        element={<PrivateRoute component={Conference} />}
    />,
];

const getSectionId = () => {
    const isOldView = window.location.pathname.includes('v1');
    return isOldView ? 'view' : 'view-v2';
};

const Routes = () => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.app.user, shallowEqual);

    useEffect(() => {
        if (user?.id) {
            Sentry.setUser({ id: user.id, email: user.email });
            dispatch(fetchAppSettings());
        }
    }, [dispatch, user]);

    const isRanchManager = usePermission('isRanchManager', 'general');
    const isFarmManager = usePermission('isFarmManager', 'general');

    const isAdminPage = !isFarmManager && !isRanchManager;

    return (
        <Router>
            <PermissionsProvider showChildrenBeforeRefresh={false} refreshUserToken>
                <AnalProvider trackingCode={TRACKING_CODE} UI_ENV={UI_ENV}>
                    <ConfProvider>
                        <AdminAppHeader isAdminPage={isAdminPage} />
                        <section id={getSectionId()}>
                            <SentryRoutes>
                                <Route path={ROUTE_PATHS.SIGNIN} element={<PublicRoute component={SignIn} />} />
                                <Route
                                    path={ROUTE_PATHS.SETUP_PASSWORD}
                                    element={<PublicRoute component={ChangePassword} />}
                                />
                                {isAdminPage ? adimRoutes : userRoutes}
                            </SentryRoutes>
                        </section>
                    </ConfProvider>
                </AnalProvider>
            </PermissionsProvider>
        </Router>
    );
};

export default Routes;
